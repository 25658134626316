<template>
  <div class="mobile-centered">
    <div v-if="useWorkoutBuilder && sport == 'Schwimmen'">
      <div class="row top-spacer-25">
        <div class="col-lg-4">
          <button style="margin-bottom: 2px" class="segment-button" v-on:click="addSwimSteadyStateToWorkoutFile()">
            Steady State
          </button>
        </div>
        <div class="col-lg-4" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addSwimRestToWorkoutFile()">Pause</button>
        </div>
        <div class="col-lg-4" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addSwimIntervalToWorkoutFile()">Intervall</button>
        </div>
      </div>

      <div class="row top-spacer-25" v-if="workoutFile.length == 0">
        <div class="col-lg-12 centered">
          <strong
            >Klicke auf einen der Buttons, um ein Element hinzuzufügen.<br />Du kannst die hinzugefügten Elemente per
            Drag und Drop verschieben</strong
          >
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12" style="overflow-x: scroll !important">
          <draggable v-model="workoutFile" @start="drag = true" @end="drag = false" class="workout-parent wo">
            <span
              class="wo-wrap"
              v-for="value in workoutFile"
              :key="value.id"
              v-bind:height="workoutBuilderHeight()"
              v-bind:class="{ active: isSelectedSegment(value.id) }"
            >
              <svg
                v-if="value.type == 'SteadyState'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Distance / 15"
                v-bind:height="value.Power || 75"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Distance / 15"
                  v-bind:height="value.Power || 50"
                  v-bind:style="'fill:' + colorForPower(value.Power)"
                />
              </svg>

              <svg
                v-if="value.type == 'Rest'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 5"
                v-bind:height="value.Power || 75"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 5"
                  v-bind:height="value.Power || 50"
                  style="fill: rgba(94, 110, 255, 0.5)"
                />
              </svg>

              <span v-if="value.type == 'IntervalsRest'">
                <span @click="selectBlock">
                  <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                    <svg class="workoutBuilderBlock" v-bind:width="value.Distance / 15" v-bind:height="75">
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.Distance / 15"
                        v-bind:height="value.Power || 75"
                        style="fill: darkred"
                      />
                    </svg>
                    <svg class="workoutBuilderBlock" v-bind:width="(value.Rest || 0) / 5" v-bind:height="75">
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="(value.Rest || 0) / 5"
                        v-bind:height="value.Power || 35"
                        v-bind:style="'fill: rgba(94, 110, 255, 0.67)'"
                      />
                    </svg>
                  </span>
                </span>
              </span>
            </span>
          </draggable>
          <div class="row" v-if="selectedWorkoutItem && selectedWorkoutItem.id != undefined">
            <div class="col-lg-12 centerd">
              <br />

              <a class="block" @click="deleteSelectedBlock" style="border-radius: 5px">Gewählten Block entfernen</a
              >&nbsp; <a class="block" @click="copySelectedBlock" style="border-radius: 5px">Kopieren</a>&nbsp;
              <br />
            </div>
          </div>
          <div class="row top-spacer-25">
            <div class="col-lg-12" v-if="selectedWorkoutItem">
              <div class="row" v-if="selectedWorkoutItem.type == 'SteadyState'">
                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Distanz</strong><br />
                  <input
                    style="width: 100%"
                    @input="setSwimDistance($event)"
                    v-model.number="selectedWorkoutItem.Distance"
                  /><br />
                  (in Metern)
                </div>
                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Schwimmstil</strong><br />
                  <!-- BACKSTROKE, BREASTSTROKE, DRILL, BUTTERFLY, FREESTYLE, MIXED, IM-->
                  <select style="width: 100%" v-model="selectedWorkoutItem.StrokeType" @input="setSwimstroke($event)">
                    <option value="FREESTYLE">Freistil</option>
                    <option value="BACKSTROKE">Rücken</option>
                    <option value="BREASTSTROKE">Brust</option>
                    <option value="BUTTERFLY">Schmetterling</option>
                    <option value="IM">Lagen</option>
                    <option value="DRILL">Drill</option>
                    <option value="MIXED">Mixed</option>
                  </select>
                </div>

                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Intensität</strong><br />
                  <input
                    style="width: 100%"
                    @input="setSwimIntensity($event)"
                    v-model.number="selectedWorkoutItem.Power"
                  /><br />
                  (in % der Schwelle)
                </div>

                <div class="col-lg-12 interval-card top-spacer-10">
                  <strong>Beschreibung</strong><br />
                  <textarea
                    style="width: 100%"
                    @input="setDescription($event)"
                    v-model.number="selectedWorkoutItem.Description"
                  ></textarea
                  ><br />
                </div>
                <!--
                <div class="col-lg-12 interval-card top-spacer-25">
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButton"
                  />
                  Mit Lap-Button beenden<br /><small
                    >muss vom Gerät unterstützt werden</small
                  >
                </div>-->
              </div>

              <div class="row" v-if="selectedWorkoutItem.type == 'Rest'">
                <div class="col-lg-4 interval-card">
                  <strong>Dauer</strong><br />
                  <input
                    min="1"
                    max="999"
                    v-model.number="selectedWorkoutItem.Duration"
                    type="number"
                    style="width: 100%; max-width: 180px"
                  />
                  <br />(In Sekunden)
                </div>
                <div class="col-lg-8 interval-card">
                  <strong>Beschreibung</strong><br />
                  <textarea
                    style="width: 100%"
                    @input="setDescription($event)"
                    v-model.number="selectedWorkoutItem.Description"
                  ></textarea
                  ><br />
                </div>
                <!--
                <div class="col-lg-12 interval-card top-spacer-25">
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButton"
                  />
                  Mit Lap-Button beenden<br /><small
                    >muss vom Gerät unterstützt werden</small
                  >
                </div>-->
              </div>

              <span v-if="selectedWorkoutItem.type == 'IntervalsRest'">
                <div class="row top-spacer-15">
                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Wiederholungen</strong><br />
                    <input style="width: 100%" v-model.number="selectedWorkoutItem.Repeat" type="number" />
                  </div>

                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Distanz</strong><br />
                    <input
                      style="width: 100%"
                      @input="setDistance()"
                      v-model.number="selectedWorkoutItem.Distance"
                    /><br />
                    (in Metern)
                  </div>
                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Pause</strong><br />
                    <input
                      min="1"
                      max="999"
                      v-model.number="selectedWorkoutItem.Rest"
                      type="number"
                      style="width: 100%"
                    />
                    <br />(In Sekunden)
                  </div>

                  <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Intensität Belastung</strong><br />
                  <input
                    style="width: 100%"
                    @input="setSwimIntensityOnPower($event)"
                    v-model.number="selectedWorkoutItem.OnPower"
                    /><br />
                    (in % der Schwelle)
                  </div>
                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Intensität Erholung</strong><br />
                  <input
                    style="width: 100%"
                    @input="setSwimIntensityOffPower($event)"
                    v-model.number="selectedWorkoutItem.OffPower"
                    /><br />
                    (in % der Schwelle)
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 interval-card top-spacer-25">
                    <strong>Schwimmstil</strong><br />
                    <!-- BACKSTROKE, BREASTSTROKE, DRILL, BUTTERFLY, FREESTYLE, MIXED, IM-->
                    <select style="width: 100%" v-model="selectedWorkoutItem.StrokeType" @input="setSwimstroke($event)">
                      <option value="FREESTYLE">Freistil</option>
                      <option value="BACKSTROKE">Rücken</option>
                      <option value="BREASTSTROKE">Brust</option>
                      <option value="BUTTERFLY">Schmetterling</option>
                      <option value="IM">Lagen</option>
                      <option value="DRILL">Drill</option>
                      <option value="MIXED">Mixed</option>
                    </select>
                  </div>
                  <div class="col-lg-8 interval-card top-spacer-25">
                    <strong>Beschreibung</strong><br />
                    <textarea
                      style="width: 100%"
                      @input="setDescription($event)"
                      v-model.number="selectedWorkoutItem.Description"
                    ></textarea
                    ><br />
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-lg-6 interval-card top-spacer-25">
                    <div>
                      <input
                        style="display: inline-block; height: inherit"
                        type="checkbox"
                        v-model="selectedWorkoutItem.endOnLapButtonOnDuration"
                      />
                      Intervall mit Lap-Button beenden<br /><small
                        >muss vom Gerät unterstützt werden</small
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 interval-card top-spacer-25">
                    <div>
                      <input
                        style="display: inline-block; height: inherit"
                        type="checkbox"
                        v-model="selectedWorkoutItem.endOnLapButtonOffDuration"
                      />
                      Pause mit Lap-Button beenden<br /><small
                        >muss vom Gerät unterstützt werden</small
                      >
                    </div>
                  </div>
                </div>-->
              </span>
              <div class="row">
                <div class="col-lg-12 top-spacer-75">
                  <button class="closer" @click="saveWorkoutBuilder()">Fertig</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="useWorkoutBuilder && sport != 'Schwimmen'">
      <div class="row top-spacer-25">
        <div class="col-lg-3">
          <button class="segment-button" v-on:click="addSteadyStateToWorkoutFile()">Steady State</button>
        </div>
        <div class="col-lg-3">
          <button class="segment-button" v-on:click="addRampToWorkoutFile()">Rampe</button>
        </div>
        <div class="col-lg-3">
          <button class="segment-button" v-on:click="addIntervalToWorkoutFile()">Intervall</button>
        </div>
        <div class="col-lg-3">
          <button class="segment-button" v-on:click="addFreeRideToWorkoutFile()">Beliebig</button>
        </div>
      </div>

      <div class="col-lg-12" v-if="workoutFile.length > 0">
        <br />Länge:
        <span
          >{{ formattedTime(totalWorkoutLength()) }} |
          <span v-if="sport == 'Radfahren'"> Ø {{ computeAvgWatt(workoutFile) }}w</span>
          <span v-if="sport == 'Laufen'">Ø {{ computeAvgRunSpeed(workoutFile) }}m/km</span>
          <span v-if="sport == 'Schwimmen'"> Ø {{ computeAvgSwimSpeed(workoutFile) }}m/km</span></span
        >
      </div>
      <div class="row top-spacer-25" v-if="workoutFile.length == 0">
        <div class="col-lg-12 centered">
          <strong>Klicke auf einen der Buttons, um ein Element hinzuzufügen.</strong>
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12">
          <draggable v-model="workoutFile" @start="drag = true" @end="drag = false" class="workout-parent wo">
            <span
              class="wo-wrap"
              v-for="value in workoutFile"
              :key="value.id"
              v-bind:class="{ active: isSelectedSegment(value.id) }"
            >
              <svg
                v-if="value.type == 'SteadyState'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 15"
                v-bind:height="workoutBuilderHeight()"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 15"
                  v-bind:height="value.Power"
                  v-bind:style="'fill:' + colorForPower(value.Power)"
                />
              </svg>

              <svg
                v-if="value.type == 'FreeRide'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 15"
                v-bind:height="100"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 15"
                  v-bind:height="80"
                  style="fill: rgba(94, 110, 255, 0.5)"
                />
              </svg>

              <svg
                v-if="value.type == 'Ramp'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 15 - 1"
                v-bind:height="workoutBuilderHeight()"
              >
                <path
                  v-bind:id="value.id"
                  v-bind:d="
                    'M 1 ' + value.PowerLow + ' L ' + value.Duration / 15 + ' ' + value.PowerHigh + ' V 0 H 1 Z'
                  "
                  v-bind:style="'fill:' + colorForPower((value.PowerLow + value.PowerHigh) / 2)"
                />
              </svg>

              <span v-if="value.type == 'IntervalsT'">
                <span @click="selectBlock">
                  <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                    <svg
                      class="workoutBuilderBlock"
                      v-bind:width="value.OnDuration / 15"
                      v-bind:height="workoutBuilderHeight()"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.OnDuration / 15"
                        v-bind:height="value.OnPower"
                        v-bind:style="'fill:' + colorForPower(value.OnPower)"
                      />
                    </svg>
                    <svg
                      class="workoutBuilderBlock"
                      v-bind:width="value.OffDuration / 15"
                      v-bind:height="workoutBuilderHeight()"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.OffDuration / 15"
                        v-bind:height="value.OffPower"
                        v-bind:style="'fill:' + colorForPower(value.OffPower)"
                      />
                    </svg>
                  </span>
                </span>
              </span>
            </span>
          </draggable>
        </div>
      </div>
      <div class="row" v-if="selectedWorkoutItem && selectedWorkoutItem.id != undefined">
        <div class="col-lg-12 centerd">
          <br />

          <a class="block" @click="deleteSelectedBlock">Gewählten Block entfernen</a>&nbsp;
          <a class="block" @click="copySelectedBlock" style="border-radius: 5px">Kopieren</a>&nbsp;
          <a
            v-if="mode == 'Duration' && sport == 'Laufen' && selectedWorkoutItem.type != 'Ramp'"
            class="block"
            @click="mode = 'Distance'"
            style="border-radius: 5px"
            >Zeitmodus</a
          >
          <a
            v-if="mode == 'Distance' && sport == 'Laufen' && selectedWorkoutItem.type != 'Ramp'"
            class="block"
            @click="
              mode = 'Duration';
              selectedWorkoutItem.Distance = 0;
              selectedWorkoutItem.OnDistance = 0;
              selectedWorkoutItem.OffDistance = 0;
            "
            style="border-radius: 5px"
            >Distanzmodus</a
          >
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12" v-if="selectedWorkoutItem">
          <div class="row" v-if="selectedWorkoutItem.type == 'SteadyState'">
            <div v-if="mode == 'Duration'" class="col-lg-4 interval-card">
              <strong>Dauer</strong><br />
              <input @input="setDuration($event)" v-model="builderDuration" /><br />
              (h:mm:ss)
            </div>
            <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
              <strong>Distanz</strong><br />
              <input @input="setDistance($event)" v-model.number="selectedWorkoutItem.Distance" /><br />
              (in Metern)
            </div>
            <div class="col-lg-6 interval-card">
              <strong>Intensität</strong><br />
              <input
                @input="setIntensity()"
                v-model.number="selectedWorkoutItem.Power"
                min="1"
                max="400"
                type="number"
                style="width: 100%; max-width: 180px"
              />
              <br />(In %)
            </div>
            <div class="col-lg-6 interval-card" v-if="sport == 'Radfahren'">
              <strong>Trittfrequenz</strong><br />
              <input
                v-model.number="selectedWorkoutItem.Cadence"
                min="0"
                max="400"
                type="number"
                style="width: 100%; max-width: 180px"
              />
              <br />(0=Beliebig)
            </div>
            <div class="col-lg-12 interval-card top-spacer-25">
              <input
                style="display: inline-block; height: inherit"
                type="checkbox"
                v-model="selectedWorkoutItem.endOnLapButton"
              />
              Mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
            </div>
          </div>

          <div class="row" v-if="selectedWorkoutItem.type == 'FreeRide'">
            <div v-if="mode != 'Distance'" class="col-lg-6 interval-card">
              <strong>Dauer</strong><br />
              <input @input="setDuration($event)" v-model="builderDuration" /><br />
              (h:mm:ss)
            </div>
            <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
              <strong>Distanz</strong><br />
              <input @input="setDistance($event)" v-model.number="selectedWorkoutItem.Distance" /><br />
              (in Metern)
            </div>

            <div class="col-lg-12 interval-card top-spacer-25">
              <div>
                <input
                  style="display: inline-block; height: inherit"
                  type="checkbox"
                  v-model="selectedWorkoutItem.endOnLapButton"
                />
                Mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
              </div>
            </div>
          </div>

          <span v-if="selectedWorkoutItem.type == 'Ramp'">
            <div class="row">
              <div class="col-lg-4 interval-card">
                <strong>Dauer</strong><br />
                <input @input="setDuration($event)" v-model="builderDuration" />
                <br />(h:mm:ss)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität Start</strong><br />
                <input
                  @input="setOnIntensity()"
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.PowerLow"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität Ende</strong><br />
                <input
                  @input="setOffIntensity()"
                  v-model.number="selectedWorkoutItem.PowerHigh"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <div class="col-lg-4 interval-card" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.Cadence"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
            </div>
          </span>

          <span v-if="selectedWorkoutItem.type == 'IntervalsT'">
            <div class="row">
              <div class="col-lg-4 interval-card">
                <strong>Wiederholungen</strong><br />
                <input v-model.number="selectedWorkoutItem.Repeat" type="number" />
              </div>
              <div v-if="mode != 'Distance'" class="col-lg-4 interval-card">
                <strong>Dauer</strong><br />
                <input @input="setOnDuration($event)" v-model="builderOnDuration" />
                <br />(h:mm:ss)
              </div>
              <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
                <strong>Distanz</strong><br />
                <input @input="setOnDistance()" v-model.number="selectedWorkoutItem.OnDistance" /><br />
                (in Metern)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität</strong><br />
                <input
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.OnPower"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <div class="row">
                <div class="col-lg-6 interval-card top-spacer-25">
                  <div>
                    <input
                      style="display: inline-block; height: inherit"
                      type="checkbox"
                      v-model="selectedWorkoutItem.endOnLapButtonOnDuration"
                    />
                    Intervall mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
                  </div>
                </div>
                <div class="col-lg-6 interval-card top-spacer-25">
                  <div>
                    <input
                      style="display: inline-block; height: inherit"
                      type="checkbox"
                      v-model="selectedWorkoutItem.endOnLapButtonOffDuration"
                    />
                    Pause mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"></div>
              <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
                <strong>Distanz</strong><br />
                <input @input="setOffDistance()" v-model.number="selectedWorkoutItem.OffDistance" /><br />
                (in Metern)
              </div>
              <div v-if="mode != 'Distance'" class="col-lg-4 interval-card">
                <strong>Dauer Pause</strong><br />
                <input @input="setOffDuration($event)" v-model="builderOffDuration" />
                <br />(h:mm:ss)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität Pause</strong><br />
                <input
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.OffPower"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"></div>

              <div class="col-lg-4 interval-card" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.Cadence"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
              <div class="col-lg-4 interval-card" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz Pause</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.CadenceResting"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="row top-spacer-25" v-if="workoutFile && workoutFile.length > 0">
        <div class="col-lg-12">
          <table class="table" style="text-align: left">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Typ</th>

                <th scope="col">Segment</th>
                <th scope="col" v-if="sport == 'Radfahren'">Trittfrequenz</th>
                <th scope="col" class="centered">Löschen</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(segment, index) in workoutFile"
                :key="segment.id + index"
                @click="selectBlock({}, segment.id)"
                class="segment-block"
                v-bind:style="selectedWorkoutItem.id == segment.id ? 'background:#ddd' : ''"
              >
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <span v-if="segment.type == 'Ramp'">Rampe </span>
                  <span v-if="segment.type == 'SteadyState'"> Steady State </span>
                  <span v-if="segment.type == 'IntervalsT'"> Intervalle </span>
                  <span v-if="segment.type == 'FreeRide'"> Beliebig </span>
                </td>
                <td>
                  <span v-if="segment.type == 'Ramp'">
                    <span v-if="segment.Distance"> {{ segment.Distance }}m</span>
                    <span v-if="!segment.Distance">
                      {{ betterformattedTime(segment.Duration) }}
                    </span>
                    von

                    {{ segment.PowerLow }}% {{ computeIntensityValues(segment.PowerLow) }} bis {{ segment.PowerHigh }}%
                    {{ computeIntensityValues(segment.PowerHigh) }}
                  </span>
                  <span v-if="segment.type == 'SteadyState'">
                    <span v-if="segment.Distance"> {{ segment.Distance }}m</span>

                    <span v-if="!segment.Distance"> {{ betterformattedTime(segment.Duration) }}</span>
                    @ {{ segment.Power }}%
                    {{ computeIntensityValues(segment.Power) }}
                  </span>
                  <span v-if="segment.type == 'IntervalsT'">
                    Wiederhole {{ segment.Repeat }} mal<br />
                    <span v-if="segment.OnDistance"> {{ segment.OnDistance }}m</span>
                    <span v-if="!segment.OnDistance"> {{ betterformattedTime(segment.OnDuration) }}</span>
                    @ {{ segment.OnPower }}% {{ computeIntensityValues(segment.OnPower) }} und
                    <span v-if="segment.OffDistance"> {{ segment.OffDistance }}m</span>
                    <span v-if="!segment.OffDistance"> {{ betterformattedTime(segment.OffDuration) }}</span>
                    @ {{ segment.OffPower }}%

                    <!-- {{ computeIntensityValues(segment.OffPower) }}-->
                  </span>
                  <!--
                  <span v-if="segment.Distance"> {{ segment.Distance }}m</span>
                  <span v-if="!segment.Distance">{{
                    betterformattedTime(segment.Duration)
                  }}</span>-->
                </td>
                <td v-if="sport == 'Radfahren'">
                  <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                  <span v-if="segment.Cadence == 0">Beliebig</span>
                  <span v-if="segment.CadenceResting != undefined">
                    /
                    <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                    <span v-if="segment.CadenceResting == 0">Beliebig</span>
                  </span>
                </td>
                <td class="centered">
                  <span @click="deleteBlock(segment.id)" class="pointer">&#x2716;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button @click="saveWorkoutBuilder()">Fertig</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="!useWorkoutBuilder">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <form @submit.prevent="createWorkout">
                <div class="row top-spacer-25" v-if="sport != 'Notiz'">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Name des Workouts</strong>
                  </div>
                  <div class="col-lg-9">
                    <input required v-model="name" type="text" style="width: 100%" />
                  </div>
                </div>
                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Sport</strong>
                  </div>
                  <div class="col-lg-9" style="text-align: center; text-align-last: center">
                    <select name="sport" v-model="sport" style="width: 100%">
                      <option selected>Laufen</option>
                      <option>Radfahren</option>
                      <option>Schwimmen</option>
                      <option value="Kraft">Athletik</option>
                      <option v-if="!wo || wo.sport == 'Notiz'" value="" disabled="disabled">──────</option>
                      <option v-if="!wo || wo.sport == 'Notiz'">Notiz</option>
                    </select>
                  </div>
                </div>

                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile" v-if="sport != 'Notiz'">Beschreibung</strong>
                    <strong class="pull-right-non-mobile" v-if="sport == 'Notiz'">Notiz</strong>
                  </div>
                  <div class="col-lg-9">
                    <textarea-autosize
                      required
                      v-model="description"
                      type="text"
                      placeholder="Beschreibung"
                      style="width: 100%"
                      :min-height="90"
                    />
                  </div>
                </div>

                <div class="row" v-if="youtubeEmbedLink">
                  <div class="col-lg-12 top-spacer-25">
                    <iframe
                      width="100%"
                      height="315"
                      v-bind:src="youtubeEmbedLink"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <br /><br />
                </div>

                <div class="row" v-if="sport == 'Radfahren' || sport == 'Laufen' || sport == 'Schwimmen'">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-9 top-spacer-25">
                    <button @click="useWorkoutBuilder = true" style="width: 100%">Workout Builder verwenden</button>
                    <hr v-if="!workoutFile || workoutFile.length == 0" />
                  </div>
                </div>

                <div
                  class="row top-spacer-25"
                  v-if="workoutFile && workoutFile.length > 0 && (sport == 'Radfahren' || sport == 'Laufen')"
                >
                  <div class="col-lg-12">
                    <table class="table" style="text-align: left">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Typ</th>

                          <th scope="col">Segment</th>
                          <th scope="col" v-if="sport == 'Radfahren'">Trittfrequenz</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(segment, index) in workoutFile" :key="segment.id + index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>
                            <span v-if="segment.type == 'Ramp'">Rampe </span>
                            <span v-if="segment.type == 'SteadyState'"> Steady State </span>
                            <span v-if="segment.type == 'IntervalsT'"> Intervalle </span>
                            <span v-if="segment.type == 'FreeRide'"> Beliebig </span>
                          </td>
                          <td>
                            <span v-if="segment.type == 'Ramp'">
                              {{ betterformattedTime(segment.Duration) }} von {{ segment.PowerLow }}%
                              {{ computeIntensityValues(segment.PowerLow) }} bis {{ segment.PowerHigh }}%
                              {{ computeIntensityValues(segment.PowerHigh) }}
                            </span>
                            <span v-if="segment.type == 'SteadyState'">
                              {{ betterformattedTime(segment.Duration) }} @ {{ segment.Power }}%
                              {{ computeIntensityValues(segment.Power) }}
                            </span>
                            <span v-if="segment.type == 'IntervalsT'">
                              Wiederhole {{ segment.Repeat }} mal<br />
                              {{ betterformattedTime(segment.OnDuration) }} @ {{ segment.OnPower }}%
                              {{ computeIntensityValues(segment.OnPower) }} und
                              {{ betterformattedTime(segment.OffDuration) }} @ {{ segment.OffPower }}%
                              {{ computeIntensityValues(segment.OffPower) }}
                            </span>
                            <span v-if="segment.type == 'FreeRide'">
                              {{ betterformattedTime(segment.Duration) }}
                            </span>
                          </td>
                          <td v-if="sport == 'Radfahren'">
                            <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                            <span v-if="segment.Cadence == 0">Beliebig</span>
                            <span v-if="segment.CadenceResting != undefined">
                              /
                              <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                              <span v-if="segment.CadenceResting == 0">Beliebig</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr v-if="workoutFile && workoutFile.length != 0" />

                <div v-if="sport != 'Notiz'">
                  <div class="row top-spacer-25">
                    <div class="col-lg-1"></div>

                    <div class="col-lg-4"></div>
                    <div class="col-lg-6 d-none d-lg-block">Soll</div>
                    <div class="col-lg-1"></div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <strong class="pull-right-non-mobile">Dauer <small>(hh:mm)</small></strong>
                    </div>
                    <div class="col-lg-6 col-6">
                      <span class="d-block d-lg-none">Soll</span>
                      <input required v-model="durationShould" type="text" style="width: 100%" />
                    </div>

                    <div class="col-lg-1"></div>
                  </div>

                  <div v-if="sport == 'Schwimmen'" class="row top-spacer-25">
                    <div class="col-lg-6">
                      <strong class="pull-right-non-mobile"
                        >Distanz
                        <small>(km)</small>
                      </strong>
                    </div>
                    <div class="col-6">
                      <span class="d-block d-lg-none">Soll</span>

                      <input required v-model="distanceShould" type="text" style="width: 100%" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="font-size: 16px" v-if="user.isAdmin" class="top-spacer-25">
      <div style="font-size: 16px" v-if="user.isAdmin">
        (ADMIN) Youtube Video einbinden <br /><input type="text" v-model="youtubeEmbedLink" />
      </div>

      (ADMIN) Als globale Vorlage in Bibliothek aufnehmen? <br /><input
        type="checkbox"
        id="checkbox"
        v-model="isSaveAsGlobalTemplate"
      />
    </div>
    <div class="row top-spacer-25" v-if="!useWorkoutBuilder && sport != 'Notiz' && sport != 'Wettkampf'">
      <div class="col-12 col-lg-3"></div>
      <div class="col-4 col-lg-3">
        <span>LIT (%)</span><br />
        <input required v-model="estimate_time_lit" type="number" style="width: 100%; max-width: 120px" />
      </div>
      <div class="col-4 col-lg-3">
        <span>MIT (%)</span><br />
        <input required v-model="estimate_time_mit" type="number" style="width: 100%; max-width: 120px" />
      </div>
      <div class="col-4 col-lg-3">
        <span>HIT (%)</span><br />
        <input required v-model="estimate_time_hit" type="number" style="width: 100%; max-width: 120px" />
      </div>
      <hr class="top-spacer-25" />
    </div>
    <div class="row">
      <div class="col-lg-4 top-spacer-25" v-if="!useWorkoutBuilder">
        <div>
          <button type="submit" @click="createWorkout()">Speichern</button>
        </div>
      </div>

      <div class="col-lg-4 top-spacer-25" v-if="!useWorkoutBuilder">
        <div>
          <button
            type="submit"
            class="delete"
            v-if="workout != undefined"
            @click="deleteWorkoutConfirmation = !deleteWorkoutConfirmation"
          >
            <i class="fa fa-trash-o" aria-hidden="true"></i> Löschen
          </button>
        </div>
      </div>

      <div class="col-lg-4 top-spacer-25" v-if="!useWorkoutBuilder">
        <div>
          <button class="closer" type="submit" @click="createCopyOfWorkout()">Kopie anlegen</button>
        </div>
      </div>

      <div class="warning col-12" v-if="deleteWorkoutConfirmation">
        <span v-if="sport != 'Notiz'"
          >Achtung: Beim Löschen gehen alle eingegeben Daten der Einheit verloren.<br /><br
        /></span>
        <span v-if="sport == 'Notiz'">Möchtest du die Notiz löschen?<br /><br /></span>
        <button type="submit" class="delete" @click="deleteVorlage()">
          <span v-if="deleteWorkoutConfirmation">Wirklich löschen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import calendar_utils from "@/js/calendar_utils";
import draggable from "vuedraggable";

export default {
  name: "NewWorkout",
  components: { draggable },
  props: ["trainingsplan_id", "day", "workout", "thresholds"],
  data() {
    return {
      mode: "Duration",
      isSaveAsGlobalTemplate: false,
      // Workout by Props
      wo: undefined,
      deleteWorkoutConfirmation: false,
      youtubeEmbedLink: undefined,

      // Auf Server spichern
      name: "Mein Workout",
      description: "",
      sport: "Radfahren",
      workoutFile: [],

      // Wenn kein WorkoutBuilder benutzt wird
      durationShould: 0,
      distance: 0,
      distanceShould: 0,

      // Funktionales
      selectedWorkoutItem: {},

      estimate_time_lit: 0,
      estimate_time_mit: 0,
      estimate_time_hit: 0,

      workoutLengthIs: "0:00:00",
      performance: "Passend",

      ftp: this.thresholds.ftp,
      swimThreshold: this.thresholds.threshold_swim,
      runThreshold: this.thresholds.threshold_run,

      details: {},

      // TODO: Set max repeats to 250 in form
      // Die Variable wird als "Hack" verwendet, um eine For-Schleife mit vue zu realisieren
      list: new Array(250),
      uri: this.$host,

      useWorkoutBuilder: false,
      builderDuration: "",
      builderOffDuration: "",
      builderOnDuration: "",
      user: {},
    };
  },
  watch: {
    sport(newSport) {
      // Wenn zum Beispiel von Laufen zu Radfahren gewechselt wird,
      // so müssen ggf. gesetzte Distanzen im Segment entfernt werden!
      if (newSport != "Laufen" && newSport != "Schwimmen") {
        this.mode = "Duration";
      }
    },
  },

  mounted: async function () {
    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  computed: {
    isInPast: function () {
      return this.$moment(this.date).isBefore();
    },
  },
  methods: {


    setSwimDistance(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Distance = event.target.value;
      }
    },

    setSwimIntensity(event) { 
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Power = event.target.value;
      }
    },

    setSwimIntensityOnPower(event) { 
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OnPower = event.target.value;
      }
    },

    setSwimIntensityOffPower(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OffPower = event.target.value;
      }
    },

    setSwimstroke(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.SwimStroke = event.target.value;
      }
    },

    setDescription(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Description = event.target.value;
      }
    },

    setDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Duration = moment.duration(event.target.value).asSeconds();
      }
    },

    setDistance(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Duration = Math.round(
          (event.target.value / 1000) * (this.runThreshold / (this.selectedWorkoutItem.Power / 100))
        );
      }
    },
    setOnDistance() {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OnDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OnPower / 100))
        );
      }
    },
    setOffDistance() {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OffDuration = Math.round(
          (this.selectedWorkoutItem.OffDistance / 1000) *
            (this.runThreshold / (this.selectedWorkoutItem.OffPower / 100))
        );
      }
    },
    setIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.Distance > 0) {
        this.selectedWorkoutItem.Duration = Math.round(
          (this.selectedWorkoutItem.Distance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.Power / 100))
        );
      }
    },

    setOnIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.OnDistance > 0) {
        this.selectedWorkoutItem.OnDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OnPower / 100))
        );
      }
    },

    setOffIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.OffDistance > 0) {
        this.selectedWorkoutItem.OffDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OffPower / 100))
        );
      }
    },

    deleteVorlage() {
      const _self = this;
      axios
        .delete(_self.$host + "/workout/blueprint/" + _self.workout._id)
        .then(function () {
          axios
            .get(_self.$host + "/workout/blueprints2/v2")
            .then(function () {
              _self.$emit("wasSaved");
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //TODO: DRY
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },

    setOnDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OnDuration = moment.duration(event.target.value).asSeconds();
      }
    },

    setOffDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OffDuration = moment.duration(event.target.value).asSeconds();
      }
    },

    showDetails() {
      if (this.workout.activityFile || this.workout.summaryId) {
        this.$router.push("/auswertung/workout/details/" + this.workout._id);
      }
    },
    compute_workout_load() {
      if (this.workoutFile && this.workoutFile.length > 0) {
        let time_lit = 0;
        let time_hit = 0;
        let time_mit = 0;

        try {
          for (const entry of this.workoutFile) {
            if (entry.Power <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.Power > 80 && entry.Power <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.Power > 101) {
              time_hit += entry.Duration;
            }

            // PowerLow und PowerHigh werden einfach aufaddiert und gleichen sich aus
            if (entry.PowerLow <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.PowerLow > 80 && entry.PowerLow <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.PowerLow > 101) {
              time_hit += entry.Duration;
            }
            if (entry.PowerHigh <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.PowerHigh > 80 && entry.PowerHigh <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.PowerHigh > 101) {
              time_hit += entry.Duration;
            }

            if (entry.OnPower <= 80) {
              time_lit += entry.OnDuration * entry.Repeat;
            }
            if (entry.OnPower > 80 && entry.OnPower <= 101) {
              time_mit += entry.OnDuration * entry.Repeat;
            }
            if (entry.OnPower > 101) {
              time_hit += entry.OnDuration * entry.Repeat;
            }

            if (entry.OffPower <= 80) {
              time_lit += entry.OffDuration * entry.Repeat;
            }
            if (entry.OffPower > 80 && entry.OffPower <= 101) {
              time_mit += entry.OffDuration * entry.Repeat;
            }
            if (entry.OffPower > 101) {
              time_hit += entry.OffDuration * entry.Repeat;
            }
          }

          const total = time_lit + time_mit + time_hit;
          this.estimate_time_lit = Math.round((time_lit / total) * 100);
          this.estimate_time_mit = Math.round((time_mit / total) * 100);
          this.estimate_time_hit = Math.round((time_hit / total) * 100);
        } catch (err) {
          console.log("Load konnte nicht geschaetzt werden: " + err);
        }
      } else {
        if (this.estimate_time_lit == 0 && this.estimate_time_mit == 0 && this.estimate_time_hit == 0) {
          console.log("Set estimates");
          // kein Workoutfile Durchschnittswert
          this.estimate_time_lit = Math.round(90);
          this.estimate_time_mit = Math.round(7);
          this.estimate_time_hit = Math.round(3);
        }
      }
    },
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    saveWorkoutBuilder() {
      this.durationShould = this.$moment()
        .startOf("day")
        .seconds(this.totalWorkoutLength() || 0)
        .format("H:mm");
      this.compute_workout_load();

      this.useWorkoutBuilder = false;
    },

    computeIntensityValues(val) {
      if (this.sport == "Laufen" || this.sport == "Schwimmen") {
        const threshold = this.runThreshold || this.swimThreshold;
        return "(" + this.$moment.utc(Math.round(threshold / (val / 100)) * 1000).format("mm:ss") + "/km)";
      }
      if (this.sport == "Radfahren") {
        return "(" + Math.round((this.ftp * val) / 100) + "w)";
      }
    },

    maxPowerInWorkout() {
      return this.workoutBuilderHeight();
    },

    workoutBuilderHeight() {
      let maxPower = Math.max.apply(
        Math,
        this.workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return maxPower;
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.runThreshold))
        .format("mm:ss");
    },

    computeAvgSwimSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.swimThreshold))
        .format("mm:ss");
    },

    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.ftp);
    },

    addSwimSteadyStateToWorkoutFile() {
      const elem = {
        type: "SteadyState",
        id: this.generateGUid(),
        Distance: 100,
        StrokeType: "FREESTYLE",
        Description: "",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSwimRestToWorkoutFile() {
      const elem = {
        type: "Rest",
        id: this.generateGUid(),
        Duration: 30,
        Description: "",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSwimIntervalToWorkoutFile() {
      const elem = {
        type: "IntervalsRest",
        id: this.generateGUid(),
        Repeat: 3,
        Distance: 100,
        Rest: 20,
        StrokeType: "FREESTYLE",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSteadyStateToWorkoutFile() {
      const elem = {
        type: "SteadyState",
        id: this.generateGUid(),
        Duration: 600,
        Power: 70,
        Cadence: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    addRampToWorkoutFile() {
      const elem = {
        type: "Ramp",
        id: this.generateGUid(),
        Duration: 600,
        PowerLow: 50,
        PowerHigh: 70,
        Cadence: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    addIntervalToWorkoutFile() {
      const elem = {
        type: "IntervalsT",
        id: this.generateGUid(),
        Repeat: 3,
        OffDuration: 90,
        OnDuration: 30,
        OnPower: 120,
        OffPower: 70,
        Cadence: 0,
        CadenceResting: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    addFreeRideToWorkoutFile() {
      // <FreeRide Duration="600" FlatRoad="1"/>
      const elem = {
        type: "FreeRide",
        id: this.generateGUid(),
        Duration: 300,
        FlatRoad: 1,
        Cadence: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    workoutDoneColor() {
      const durationDone = (this.wo && this.wo.durationShould) || this.totalWorkoutLength();
      if (!this.parseShouldTime()) {
        return "inherit";
      } else {
        return calendar_utils.colorForDuration(durationDone, this.parseShouldTime(), this.date);
      }
    },

    parseShouldTime() {
      try {
        // TODO FIX ME
        /* if(this.workoutLengthIs && this.workoutLengthIs.length > 0) {
          let str = this.workoutLengthIs.trim();
          str = str.split("h");
          const h = parseInt(str[0]);

          str.shift();
          if(str.length == 0) str.push(0);
          str = str.join()
          const m = parseInt(str[0]);
          const res = 60*60*h+60*m;
          console.log("PRASETIME " + res);
          return res;
        }
      } catch(err) {
        return 0;
      }*/
        return this.$moment.duration(this.workoutLengthIs).asSeconds();
      } catch (err) {
        return 0;
      }
    },

    isSelectedSegment(id) {
      if (this.selectedWorkoutItem.id == id) {
        return true;
      }
    },

    getDateForTimestamp() {
      return this.$moment(this.date, "DD.MM.YYYY").toDate();
    },

    getFormattedDateForTimestamp() {
      return this.$moment(this.date).format("DD.MM.YYYY");
    },

    deleteBlock(id) {
      this.workoutFile = this.workoutFile.filter(function (el) {
        return el.id != id;
      });

      if (this.workoutFile[0]) {
        const event = {};
        event.target = {};
        event.target.id = this.workoutFile[this.workoutFile.length - 1].id;
        this.selectBlock(event);
      } else {
        this.selectedWorkoutItem = undefined;
      }
    },

    deleteSelectedBlock() {
      let id = this.selectedWorkoutItem.id;
      this.workoutFile = this.workoutFile.filter(function (el) {
        return el.id != id;
      });
      if (this.workoutFile[0]) {
        const event = {};
        event.target = {};
        event.target.id = this.workoutFile[this.workoutFile.length - 1].id;
        this.selectBlock(event);
      } else {
        this.selectedWorkoutItem = undefined;
      }
    },

    copySelectedBlock() {
      let elem = { ...this.selectedWorkoutItem };
      elem.id = this.generateGUid();
      this.workoutFile.push(elem);

      this.selectNewBlock(elem);
    },

    totalWorkoutLength() {
      const _self = this;
      if (this.workout && this.workout.sport == "Schwimmen") {
        return this.workout.durationShould;
      }

      let length = this.workoutFile.reduce(function (prev, cur) {
        let distance = prev;
        let interval_distance = 0;

        if (!(cur.Distance > 0 || cur.OnDistance > 0 || cur.OffDistance > 0)) {
          distance +=
            (cur.Duration > 0 ? cur.Duration : 0) +
            (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0);
        }

        if (cur.Distance > 0) {
          distance += Math.round((cur.Distance / 1000) * (_self.runThreshold / (cur.Power / 100)));
        }

        if (cur.OnDistance > 0) {
          interval_distance = Math.round((cur.OnDistance / 1000) * (_self.runThreshold / (cur.OnPower / 100)));

          distance += interval_distance * cur.Repeat;
        }
        if (cur.OffDistance > 0) {
          interval_distance = Math.round((cur.OffDistance / 1000) * (_self.runThreshold / (cur.OffPower / 100)));

          distance += interval_distance * cur.Repeat;
        }

        return distance;
      }, 0);

      return length;
    },

    selectBlock(event, index = undefined) {
      let id = index;
      if (event && event.target) {
        id = event.target.id;
      }
      this.workoutFile.forEach((element) => {
        if (element.id == id) {
          // Toggle
          if (this.selectedWorkoutItem.id != id) {
            this.selectedWorkoutItem = element;

            this.builderDuration = moment.utc(element.Duration * 1000).format("HH:mm:ss");
            this.builderOnDuration = moment.utc(element.OnDuration * 1000).format("HH:mm:ss");
            this.builderOffDuration = moment.utc(element.OffDuration * 1000).format("HH:mm:ss");
          } else {
            this.selectedWorkoutItem = {};
          }
        }
      });
    },
    selectNewBlock(element) {
      this.selectedWorkoutItem = element;
      this.builderDuration = moment.utc(element.Duration * 1000).format("HH:mm:ss");
      this.builderOnDuration = moment.utc(element.OnDuration * 1000).format("HH:mm:ss");
      this.builderOffDuration = moment.utc(element.OffDuration * 1000).format("HH:mm:ss");

      this.mode = "Duration";
    },
    betterformattedTime(seconds) {
      if (seconds >= 3600) {
        return this.$moment.utc(seconds * 1000).format("H[h]mm[m]ss[s]");
      } else {
        return this.$moment.utc(seconds * 1000).format("mm[m]ss[s]");
      }
    },

    createWorkout: function () {
      let name = this.name;
      let description = this.description;
      let sport = this.sport;
      let youtubeEmbedLink = this.youtubeEmbedLink;

      let distanceShould = 0;
      if (this.distanceShould) {
        distanceShould = parseFloat(String(this.distanceShould).replace(/,/g, "."));
      }

      // Distanz berechnen, falls duration und pace gegeben

      let workout = [];
      if (sport == "Radfahren" || sport == "Laufen" || sport == "Schwimmen") {
        workout = this.workoutFile;
      }

      const durShould = moment(this.durationShould, "H:mm").diff(moment().startOf("day"), "seconds");

      let req = {
        name: name,
        distanceShould,
        sport: sport,
        youtubeEmbedLink: youtubeEmbedLink,
        estimate_time_lit: this.estimate_time_lit || 0,
        estimate_time_mit: this.estimate_time_mit || 0,
        estimate_time_hit: this.estimate_time_hit || 0,
        description: description,
        durationShould: durShould,
        inGlobalLibrary: this.isSaveAsGlobalTemplate || false,
        workoutFile: workout,
      };

      if (this.youtubeEmbedLink) {
        req.youtubeEmbedLink = this.youtubeEmbedLink;
      }

      if (this.workout) {
        req._id = this.workout._id;
      }
      const _self = this;

      axios
        .post(
          this.$host + "/workout/vorlageblueprint",

          req
        )
        .then(function () {
          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });

      //this.$store.dispatch('login', { email, password })
      //.then(() => this.$router.push('/'))
      //.catch(err => console.log(err))
    },

    createCopyOfWorkout: function () {
      let youtubeEmbedLink = this.youtubeEmbedLink;

      let name = this.name;
      let description = this.description;
      let sport = this.sport;
      let distanceShould = 0;
      if (this.distanceShould) {
        distanceShould = parseFloat(String(this.distanceShould).replace(/,/g, "."));
      }

      // Distanz berechnen, falls duration und pace gegeben

      let workout = [];
      if (sport == "Radfahren" || sport == "Laufen" || sport == "Schwimmen") {
        workout = this.workoutFile;
      }

      const durShould = moment(this.durationShould, "H:mm").diff(moment().startOf("day"), "seconds");

      let req = {
        name: name,
        distanceShould,
        sport: sport,
        youtubeEmbedLink: youtubeEmbedLink,
        estimate_time_lit: this.estimate_time_lit || 0,
        estimate_time_mit: this.estimate_time_mit || 0,
        estimate_time_hit: this.estimate_time_hit || 0,
        description: description,
        durationShould: durShould,
        inGlobalLibrary: this.isSaveAsGlobalTemplate || false,
        workoutFile: workout,
      };

      const _self = this;

      axios
        .post(
          this.$host + "/workout/vorlageblueprint",

          req
        )
        .then(function () {
          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    detailsForWorkout() {
      console.log("Load Details");
      const _self = this;
      axios
        .get(this.$host + "/workout/" + this.workout._id + "/details")
        .then(function (res) {
          // Event Namen anpassen?
          console.log("DETAILS CALLED", res.data);
          _self.details = res.data;
        })
        .catch(function (error) {
          console.log("err in details!", error);
        });
    },
    preDeleteWorkout() {
      this.deleteWorkoutConfirmation = true;
    },

    generateGUid() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
  },

  created() {
    if (this.workout) {
      this.name = this.workout.name;
      this.description = this.workout.description;
      this.sport = this.sport = this.workout.sport;
      this.distance = this.workout.distance;
      this.distanceShould = this.workout.distanceShould;
      this.time_lit = this.workout.time_lit;
      this.time_mit = this.workout.time_mit;
      this.time_hit = this.workout.time_hit;
      this.isSaveAsGlobalTemplate = this.workout.inGlobalLibrary;
      this.youtubeEmbedLink = this.workout.youtubeEmbedLink;

      this.estimate_time_lit = this.workout.estimate_time_lit;
      this.estimate_time_mit = this.workout.estimate_time_mit;
      this.estimate_time_hit = this.workout.estimate_time_hit;

      this.durationShould = this.$moment()
        .startOf("day")
        .seconds(this.workout.durationShould || 0)
        .format("H:mm");

      this.paceShould = this.$moment()
        .startOf("day")
        .seconds(this.workout.paceShould || 0)
        .format("mm:ss");

      this.workoutFile = this.workout.workoutFile;
      this.date = moment(this.workout.date).format("DD.MM.YYYY");

      if (this.workoutFile.length > 0) {
        this.selectedWorkoutItem = this.workoutFile[0];

        if (this.sport != "Schwimmen") {
          if (
            this.selectedWorkoutItem.Distance ||
            this.selectedWorkoutItem.OnDistance ||
            this.selectedWorkoutItem.OffDistance
          ) {
            this.mode = "Distance";
          } else {
            this.mode = "Duration";
          }

          this.builderDuration = moment.utc(this.selectedWorkoutItem.Duration * 1000).format("HH:mm:ss");
          this.builderOnDuration = moment.utc(this.selectedWorkoutItem.OnDuration * 1000).format("HH:mm:ss");
          this.builderOffDuration = moment.utc(this.selectedWorkoutItem.OffDuration * 1000).format("HH:mm:ss");
        }
        this.saveWorkoutBuilder();
      }

      this.wo = this.workout;
    }
  },
};
</script>

<style>
.intensitaet {
  cursor: pointer;
  padding: 10px;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.wo-wrap {
  margin-right: 2px;
}

.warning {
  padding: 15px;
  border: 2px solid darkred;
  margin: 10px 0;
  color: darkred;
}

.active {
  border-bottom: 6px solid #000;
  margin-bottom: -6px;
}

.soll {
  padding: 9px;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}

.lit-hinweis {
  padding: 15px;
}

svg {
  transform: scaleY(-1);
}

.workout-parent {
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: center;
  white-space: nowrap;
  align-items: flex-end;
  justify-content: center;
  padding: 6px;
}

.wo {
  overflow-x: scroll !important;
}

.workoutBuilderBlock {
  display: inline-block;
}

.intensitaet {
  font-size: 16px;
  font-weight: bold;
}

.intensitaet img {
  width: 35px;
}

.interval-card {
}

.performance_active {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 8px;
}

.performance_not_active {
  padding: 8px;
}

.segment-button {
  width: 100%;
  padding: 10px 0;
}
</style>
